import * as React from "react"
import styled from "styled-components"
import { FC } from "react"

const SingleColor = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
  margin: 2px;
`

interface Props {
  color: string
}

const ColorSquare: FC<Props> = ({ color }) => {
  const fixedColor = color.includes("#") ? color : `#${color}`
  return <SingleColor color={fixedColor} />
}

export default ColorSquare
