import React from "react"
import { graphql } from "gatsby"
import * as impactTransformer from "../gatsby/transformers/impactTransformer"
import SearchPage from "../social-supermarket/pages/search/SearchPage"

const Search = ({ data: { allWpImpact } }) => {
  const impacts = allWpImpact?.nodes ? impactTransformer.multipleFromGatsby(allWpImpact?.nodes) : []

  return <SearchPage impacts={impacts} />
}

export default Search

export const query = graphql`
  {
    allWpImpact {
      nodes {
        name
        slug
        icon {
          plainIcon {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
