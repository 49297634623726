import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import ImageType from "../../model/ImageType"
import GImage from "../../../gatsby/GImage"

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  outline: none;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "none")};
  border: ${({ inFocus }) => (inFocus ? "1px solid #99ddd8" : "none")};
  box-shadow: ${({ inFocus }) => (inFocus ? "0 0 10px #99ddd8" : "none")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};

  .tss-image-primary {
    opacity: 1;
  }
  .tss-image-secondary {
    opacity: 0;
  }

  &:hover {
    .tss-image-primary {
      opacity: ${({ hasSecond }) => (hasSecond ? "0" : "1")};
    }
    .tss-image-secondary {
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface Props {
  image: ImageType
  secondImage?: ImageType
  onClick?: () => void
  inFocus?: boolean
  objectFit?: "cover" | "contain"
  sizes?: string
  backgroundColor?: string
}

const SquareImage: FC<Props> = ({
  image,
  secondImage,
  onClick,
  inFocus,
  objectFit = "contain",
  sizes,
  backgroundColor,
}) => {
  return (
    <Container
      hasSecond={secondImage}
      onClick={onClick}
      inFocus={inFocus}
      backgroundColor={backgroundColor}
    >
      {image && (
        <ImageContainer className="tss-image-primary">
          <GImage
            image={image}
            style={{ height: "100%", width: "100%" }}
            objectFit={objectFit}
            sizes={sizes}
          />
        </ImageContainer>
      )}
      {secondImage && (
        <ImageContainer className="tss-image-secondary">
          <GImage
            image={secondImage}
            style={{ height: "100%", width: "100%" }}
            objectFit={objectFit}
            sizes={sizes}
          />
        </ImageContainer>
      )}
    </Container>
  )
}

export default SquareImage
